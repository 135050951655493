import React from 'react';

import { Action } from '@mshops-components-library/common';

import { useRenderContext } from '../../pages/home/context';


const ActionContainer = (props) => {
  const { shopModel } = useRenderContext();
  const { domain = '' } = shopModel?.shop || shopModel?.storefront;

  return (
    <Action {...props} domain={domain} />
  );
};

export default ActionContainer;
