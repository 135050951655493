import React from 'react';
import PropTypes from 'prop-types';
import { useRenderContext } from '../../pages/home/context';

function PaymentMethodsWrapper(PaymentData, resolution) {
  function PaymentMethodsBase({ styles, ...props }) {
    const { siteId, lowEnd } = useRenderContext();

    return (
      <PaymentData
        deviceType="desktop"
        type="payment-data"
        siteId={siteId}
        lowEnd={lowEnd}
        paymentStyles={styles[resolution]}
        {...props}
      />
    );
  }

  PaymentMethodsBase.propTypes = {
    styles: PropTypes.shape({
      small: PropTypes.object,
      large: PropTypes.object,
    }).isRequired,
  };

  return PaymentMethodsBase;
}

export default PaymentMethodsWrapper;
